body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#can-form-area-xr-seattle-sign-up-2 input[type="email"],
#can-form-area-xr-seattle-sign-up-2 input[type="text"],
#can-form-area-xr-seattle-sign-up-2 textarea {
  font-family: courier !important;
}

#can-form-area-xr-seattle-sign-up-2 {
  text-transform: uppercase;
  padding-left: 0px;
  padding-right: 0px;
}

#can_embed_form_inner h2,
#can_embed_form_inner h4 {
    display: none;
}

#can-form-area-xr-seattle-sign-up-2 input[type="submit"] {
    background-color: #ffc14d !important;
    color: black !important;
}
