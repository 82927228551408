@font-face {
  font-family: fucked;
  src: url(./fonts/FUCXEDCAPS.otf);
}

@font-face {
  font-family: crimson_regular;
  src: url('./fonts/Crimson_Text/CrimsonText-Regular.ttf');
}
.CTA {
  font-family: crimson_regular;
  font-size:20px;
  margin-top:20px;
}
.App {
  text-align: center;
}

.crimson {
  padding:15px;
  max-width:800px;
  font-family: fucked;
  font-size:30px;
}

.heading {
  MARGIN-TOP: 20px;
  padding: 0 10px 0 10px;
}

.formFrame {
  margin-top:20px;
  /* frame-border:0px; */
  max-width:500px;
  height:700px;
  min-width: 100%;
  max-width:500px;
}

.ghost {
  background-color:rgba(255, 255, 255, 0.69);
  font-family:crimson_regular;
  font-weight:600;
  font-size:34px;
  text-align: left;
  margin:50px;
  padding: 15px;
  border-radius: 5px;
}

.enumerate {
  font-size: 150px;
}


/* mobile */

@media (max-width: 340px) {
  .ghost {
    font-size: 20px;
    margin: 20px;
  }
}

.App-logo {
  margin-top:20px;
  /* height: 40vmin; */
  max-width: 90%;
}

.App-header {
  font-size:56px;
  font-family:fucked;
  color:black;
  
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

body {
  background-color: #00ae18;
  font-family: crimson_regular;
}